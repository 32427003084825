import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import Loading from './components/utils/Loading';
import MainApp from './components/main_screens/main_app';
import Login from './components/main_screens/login';
import AgeVerification from './components/main_screens/AgeVerification';
import {base} from './base';
import QonsentAPI from "./components/utils/QonsentAPI";
import Leaderboard from "./components/other_screens/Leaderboard";

function AuthenticatedRoute({component: Component, authenticated, redirectTo, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => {
                if(authenticated === true){
                    return (
                        <Component {...props} {...rest} />
                    )
                } else {
                    return (
                        <Redirect to={{pathname: {redirectTo}, state: {from: props.location}}} />
                    )
                }
            }} />
    )
}

class App extends Component {
    constructor(props) {
        super(props)
        this.setCurrentUser = this.setCurrentUser.bind(this)
        const userEmail = sessionStorage.getItem('userEmail') || false;
        this.state = {
            authenticated: !!userEmail,
            variables: {},
            loading: true,
            passedEmail: false,
            qonsentApi: null
        }
    }

    setCurrentUser() {
        const userEmail = sessionStorage.getItem('userEmail') || false;
        this.setState({
            authenticated: !!userEmail
        })
    }

    componentDidMount() {
        let splitWindowLocation = window.location.pathname.split('/');
        let language = "english"; // Default language
        if (splitWindowLocation.length > 2) {
            language = splitWindowLocation[2];
        } else if (splitWindowLocation.length > 1) {
            language = splitWindowLocation[1];
        }
        this.loadLanguage(language);
        this.tenantVariablesRef = base.listenTo(`tenantVariables`, {
            context: this,
            state: 'tenantVariables',
            then(variables){
                document.title = variables.pageTitle || "Memory Match";
                this.setUpQonsent(variables);
                this.setState({
                    authenticated: false,
                    variables: variables,
                    loading: false,
                    language: language
                })
            }
        });

    }

    async setUpQonsent(tenantVariables){
        if(!tenantVariables) return;
        // const qonsentSmartQontractId = tenantVariables.qonsentSmartQontractId;
        // if(!qonsentSmartQontractId) return;
        const api = new QonsentAPI({
            username: "admin@sqwadhq.com",
            password: "'$qw@drules'",
            baseUrl: "https://stage-api.withyourconsent.com" // Not necessary for production
        });
        const key = await api.auth();
        api.setToken(key);
        this.setState({qonsentApi: api});
    }

    checkForAgeGate(tenantVariables){
    const variable_age_boolean = tenantVariables.collectBirthday || false;
    if(!variable_age_boolean){
        return true
    }
    const formBirthday = tenantVariables.formBirthday || false;
    let variable_age = tenantVariables.allowedAge || 21;
    variable_age = parseInt(variable_age, 10);
    let user_age = sessionStorage.getItem('verifiedAge') || false;
    if(isNaN(parseInt(user_age))){
        user_age = false
    } else {
        user_age = parseInt(user_age, 10)
    }
    return (user_age && user_age >= variable_age) || formBirthday;
    }

    setPassedEmail(isSet=true){
    this.setState({
      passedEmail: isSet
    })
    }

    componentWillUnmount() {
        base.removeBinding(this.tenantVariablesRef);
    }

    async loadLanguage(language) {
        let languageConstants;
        try {
            languageConstants = await import(`./constants/languages/${language}.js`);
        } catch (e) {
            language = process.env.REACT_APP_DEFAULT_LANGUAGE || "en";  // Fallback to English or default environment language
            languageConstants = await import(`./constants/languages/${language}.js`);
        }
        this.setState({
            languageConstants
        });
    }

    render() {
        if (this.state.loading === true) {
            return (
                <Loading loading={this.state.loading}/>
            )
        }

        let redirectUrl = `/login`;
        if (this.state.language) {
            redirectUrl += ("/" + this.state.language);
        }

        return (
            <BrowserRouter>
              <Switch>
                <Route
                    exact
                    path="/login"
                    render={(props) => {
                        return <Login stringConstants={this.state.languageConstants} authenticated={this.state.authenticated} qonsentApi={this.state.qonsentApi} setCurrentUser={this.setCurrentUser} variables={this.state.variables} setPassed={(setPassed)=>this.setPassedEmail(setPassed)} checkForAgeGate={this.checkForAgeGate} {...props} />
                    }}
                />

              <AuthenticatedRoute
                  exact
                  path="/"
                  authenticated={this.state.authenticated}
                  variables={this.state.variables}
                  passedEmail={this.state.passedEmail}
                  checkForAgeGate={this.checkForAgeGate}
                  component={MainApp}
                  redirectTo={redirectUrl}
                  stringConstants={this.state.languageConstants}
              />

                {/*<Route*/}
                {/*    exact*/}
                {/*    path="/"*/}
                {/*    render={(props) => {*/}
                {/*        return <MainApp loadPage={() => logPageView()} variables={this.state.variables} passedEmail={this.state.passedEmail} checkForAgeGate={this.checkForAgeGate} {...props} />*/}
                {/*    }}*/}
                {/*/>*/}

                <Route
                    exact
                    path="/age_gate"
                    render={(props) => {
                        return <AgeVerification variables={this.state.variables} checkForAgeGate={this.checkForAgeGate} stringConstants={this.state.languageConstants} {...props} />
                    }}
                />

                <Route
                    exact
                    path="/leaderboard"
                    render={(props) => {
                        return <Leaderboard variables={this.state.variables} checkForAgeGate={this.checkForAgeGate} {...props} />
                    }}
                />

                <Redirect to={redirectUrl}/>
              </Switch>
            </BrowserRouter>
        )
    }
}

export default App;
