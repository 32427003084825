import { Component } from 'react';

const URL_BASE = "https://stage-api.withyourconsent.com";

class QonsentAPI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: null,
            authwait: null
        };
    }

    setToken = (t) => {
        this.setState({ token: t });
    }

    username = () => {
        return this.props.username;
    }

    emailOrPhoneAsUser = (user) => {
        if (user.replace(/\d/g, "").length === 0) {
            return (user.length === 10 ? "1" : "") + user;
        } else {
            return user;
        }
    }

    create = async (entities) => this.call({ svc: "create", data: JSON.stringify(entities), contentType: "application/json" });
    createAsset = async (asset) => this.call({ svc: "asset/create", data: asset, processData: false, contentType: false });
    createQontract = async (template, pii, form) => this.call({ svc: "qontract/create", data: { licensor: JSON.stringify(pii), ids: template } });
    update = async (ops) => this.call({ svc: "update", data: JSON.stringify(ops), contentType: "application/json" });
    password = async (params) => this.call({ svc: "password", data: params });
    deleteById = async (ids) => this.call({ svc: "delete", data: { ids: ids } });
    get = async (data) => this.call({ svc: "get", method: "GET", data: { id: data.id || data, dereference: data.dereference || false } });
    query = async (q) => this.call({ svc: "query", data: { q: q } });
    history = async (q) => this.call({ svc: "history", data: typeof q === "string" ? { q: q, group: false } : q });
    sendCode = async (user, brand) => this.call({ svc: "code/send", data: { username: this.emailOrPhoneAsUser(user), type: brand } });
    validateCode = async (user, brand, code) => this.call({ svc: "code/validate", data: { username: this.emailOrPhoneAsUser(user), type: brand, code: code }, reauthenticate: false });

    call = async (params) => {
        console.log("TOKEN: ", this.state.token)
        if (this.props.username && !this.state.token && !this.state.authwait) {
            await this.auth();
        }

        const callParams = {
            headers: {},
            method: params.method || "POST"
        };

        console.log(callParams)

        if (callParams.method === "POST") {
            callParams.body = params.data;
            if (params.contentType) {
                callParams.headers["Content-Type"] = params.contentType;
            }
        }

        // if (this.state.token) {
            callParams.headers.Authorization = `Bearer CWyrNH+Po9gpClLwDLLwg96NjX3K4DbxR4p8v/QnILxydeF55U5APZRrRJnGoWeMDz5gpcHOkcGHS7vumyS2/Lrvi/azFiBo82+7jKl17HUf+e3iday3x8Xq2AsJr1BSpS7RSBuY5oDeXFARHgolMLnka4pn8CK/eSNkJd6ugOFKGWR1hvxFu6A4KzHFPMgeNbKKW95K2os4iVWzder0ouV/vQALQ+nkmwj+kSnK1FW9Muje8rQb35qXwgA7Xbzoqq9ZwXrfh62V6buRVY2fp5LzwDHwcaYXC10EVX+NN3kJ/XX7RLUMI0ZAi1VeJj7p`;
        // }
        console.log(params)
        try {
            const response = await fetch(this.url(params.svc, params.data), callParams);
            if (response.status === 401) {
                await this.auth();
                return this.call(params);
            }
            if (response.headers.get("content-length") === "0") {
                return;
            }
            const data = await response.json();
            if (response.status >= 200 && response.status < 300) {
                return data;
            } else {
                throw data;
            }
        } catch (error) {
            throw error;
        }
    }

    auth = async () => {
        const response = await fetch(this.url("auth"), {
            method: "POST",
            body: new URLSearchParams({
                username: "admin@sqwadhq.com",
                password: '$qw@drules'
            })
        });

        if (response.status === 200 || response.status === 204) {
            const token = await response.text();
            this.setState({ token });
            return token;
        } else {
            const errorData = await response.json();
            throw errorData;
        }
    }

    url = (svc, params) => {
        return ((this.props && this.props.baseUrl) ? this.props.baseUrl : URL_BASE) + "/" + svc + (params ? "?" + new URLSearchParams(params) : "");
    }

    render() {
        return null;  // As this component does not render anything to the DOM
    }
}

export default QonsentAPI;
